@import 'variables';

@mixin container {
  display: flex;
  flex-direction: column;

  &_line {
    width: 100%;
  }

  &_btn {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin: 0 2rem;

    .button {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 588px) {
    &_btn {
      flex-wrap: initial;
      flex-direction: initial;
      margin: 0;
      align-self: flex-end;
      box-sizing: border-box;
      padding: 29px 2rem 20px;

      &--margin {
        margin-left: 10px;
      }
    }
  }
}

@mixin header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 2rem;

  &_text {
    padding: 20px 0 30px;
    align-self: center;
  }

  &_icon {
    padding: 46px 0 20px;
  }

  &_actions {
    padding: 2rem 2rem 20px;
  }
}

@mixin pager-item {
  @include container;

  .close-button {
    margin: 0;
  }

  &_title {
    padding-top: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_error {
    margin: 5px 0;
    color: $itm-error;
  }

  &_title-input {
    width: 0;
    flex-grow: 1;
    padding: 7px 10px 10px 10px;
    border: 0;
    font-size: 22px;
  }
}

@mixin pager-item-row {
  p {
    display: flex;
    font-weight: bold;
    flex-basis: 100%;
  }

  &-duration {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-basis: 50%;
    width: auto;
    margin: 0 -10px 30px -10px;

    &-start,
    &-end {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: auto;
      margin: 0 10px 10px 10px;
      flex-basis: calc(50% - 20px);
      @media (max-width: $sm-media) {
        flex-grow: 1;
      }

      select.month_start,
      select.month_end {
        margin-right: 10px;
        width: auto;
        flex-grow: 0.75;
        margin-bottom: 0.5rem;
        background-color: transparent;
      }
      select.month_start--white,
      select.month_end--white {
        margin-right: 10px;
        width: auto;
        flex-grow: 0.75;
        margin-bottom: 0.5rem;
        background-color: #fff;
      }

      select.year_start,
      select.year_end {
        width: 93px;
        flex-grow: 0.25;
        margin-bottom: 0.5rem;
        background-color: transparent;
      }

      select.year_start--white,
      select.year_end--white {
        width: 93px;
        flex-grow: 0.25;
        margin-bottom: 0.5rem;
        background-color: #fff;
      }
    }

    select,
    input {
      height: 40px;
      cursor: pointer;
      @include fieldBorder;
    }
  }
}

.input-label {
  margin-bottom: 8px;
}

.content-container {
  padding-top: 65px;
  padding-bottom: 30px;
}

.button__no-border {
  border: none !important;

  &:hover {
    background-color: transparent !important;
    color: $button-blue !important;
    box-shadow: none;
  }
}

.hidden {
  display: none;
}

select:focus, input:focus:not([type='checkbox']), textarea:focus {
  @include fieldBorderFocus;
}

select {
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}
