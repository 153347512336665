@import '../../styles/base.scss';
@import '../../styles//variables.scss';

.header-container {
  @include container;
}

.header {
  @include header;
}

.header-content {
  &--step {
    color: $itm-blue;
  }
}

.header_subtext {
  padding-top: 10px;
  font-size: 15px;
  line-height: 17px;
  font-style: italic;
  color: transparentize($itm-dark, 0.4);
}

.header_subtextTitle {
  padding-top: 10px;
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
}
