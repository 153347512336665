.input-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;

  &_icon {
    margin: 12px;
    width: 12px;
    height: 13px;
    opacity: 0.3;
  }

  &_input {
    flex: 1;
    width: 100%;
    border: none;

    &--editable {
      cursor: default;
      padding-left: 16px;
    }

    &:focus {
      outline: none;

      + {
        .input-container_icon {
          opacity: unset;
        }
      }
    }
  }
}
