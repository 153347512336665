@import '../../styles/variables.scss';

.remove-button {
  display: flex;
  align-items: center;
  color: $color-text;
  border: 0.5px #0098da solid;
  width: auto;
  white-space: nowrap;
  padding: 11px 14px;
  margin-left: auto;
}
