@import '../../../../../src/styles/variables';
@import '../../../../../src/styles/base';

.primary {
  margin: 0 0 0.5rem;
}

.skills {
  @include header;
  padding: 0;
  background: $itm-background;

  &-container {
    @include container;
  }

  &_list {
    display: grid;
    row-gap: 6px;

    .accordion {
      display: flex;
      flex-direction: column;
    }

    &_header {
      display: grid;
      margin-bottom: 6px;
      font-weight: 600;
      grid-template-columns: $col-1 $col-2 ($col-3 + $col-4);

      span:nth-child(2) {
        padding: 0 10px;
      }

      > *:first-child {
        padding-left: 16px;
      }
    }
  }
}
