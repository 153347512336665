@import '../../styles/variables.scss';

.hoverHintButton {
  background-color: $itm-dark;
  color: $itm-white;
  border-radius: 50%;
  border: none;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  padding: 0 !important;
}

.titleText {
  cursor: pointer;
}

.withTitle {
  margin-left: 5px;
  vertical-align: bottom;
}

ul {
  li {
    list-style-type: disc;
  }
}

p {
  white-space: pre-line;
}
