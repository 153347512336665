@import '../../styles/variables.scss';

.dotted-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  border-radius: 6px;
  width: 100%;
  background-color: transparent;
  border-style: dotted;
  border-color: $itm-blue;

  &_disabled {
    opacity: 0.2;
  }

  &_content {
    color: $itm-dark;
    padding-left: 10px;
  }
}
