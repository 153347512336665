.candidate-info {
  margin: 0 1.4rem 0 0;
  background: #ffffff;
  padding: 3rem 1.4rem 0;
  border-radius: 0.7rem;
  box-shadow: 0 8px 15px rgb(73 141 171 / 10%);

  &_header {
    margin: 0;
    font-size: 28px;
    line-height: 31px;
    word-break: break-all;

    &--blue {
      color: #0098da;
    }
  }

  &_secondary-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
  }

  &_line {
    margin: 1rem 0;
    width: 38px;
    border: 1.5px solid #ffcc00;
    background: #ffcc00;
  }

  &_text {
    padding: 0 0 59px;
    overflow-wrap: break-word;
  }

  &_icon {
    align-self: center;
  }

  &_btn-container {
    padding: 40px 0 56px;
    align-self: center;
    max-width: 169px;
  }
}
