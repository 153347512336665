@import '../../styles/variables.scss';

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &_title {
    font-size: 28px;
    line-height: 120%;
    color: $itm-dark;
    margin: 0;
  }

  &--step {
    color: $itm-blue;
  }

  &_line {
    margin: 4px 0 0 0;
    border: 1px solid $itm-blue;
    background: $itm-blue;
  }

  #headerWrapper--hoverHintButton {
    font: 600 16px / 19.2px Bariol, sans-serif;
    width: 30px;
    height: 30px;
    padding: 5px!important;
    vertical-align: text-top;
  }
}
