@import '../../../../../src/styles/variables';
@import '../../../../../src/styles/mixin';

.level {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .hit-box {
    margin-top: 0;
  }
}

.skills-level {
  display: flex;
  margin: 2rem 0;
  padding-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 1px dashed transparentize($itm-dark, 0.8);
  }

  &_wrapper {
    flex-basis: 35%;

    h5 {
      font-size: rem(18);
      font-weight: 600;
    }
  }

  &_list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 65%;
  }
}

.badge-skill {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  margin-bottom: rem(10);

  &_item {
    @include font-bold;
    min-width: rem(24);
    height: rem(24);
    border-radius: 50%;
    box-shadow: 0 0 0 rem(2) $itm-blue inset;
    color: $itm-blue;
    margin-right: rem(8);
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(13);
    text-align: center;
  }
}

.experience-wrapper {
  height: 100%;
}

.exp-label {
  display: none;
}

.skills_list_header {
  grid-template-columns: 1fr 120px 140px;
}

@media (max-width: $sm-media) {
  .skill-item {
    grid-template-columns: 1fr 140px;
  }

  .experience-wrapper {
    grid-column: span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-top: 1px solid #dee9f2;
  }

  .exp-label {
    display: block;
    padding-left: 16px;
    background: #f4f8fb;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom-left-radius: 6px;
    flex-grow: 1;

    p {
      width: 100%;
      opacity: 0.4;
    }
  }

  .skill-experience {
    margin-left: auto;
    flex-basis: 150px;
  }

  .skills_list_header {
    grid-template-columns: 1fr 140px;
  }

  .skill-item.secondary .skill-name {
    grid-column: span 2;
  }
  .skill-item.secondary .experience-wrapper {
    grid-column: span 3;
  }
}
