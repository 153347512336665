@import '../../../../styles/variables';
@import '../../../../styles/mixin';

.aboutMe-section {
  display: flex;
}

.empty-section {
  margin-bottom: 2rem;
}
