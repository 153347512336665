.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #808080;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
