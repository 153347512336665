@import '../../../../../src/styles/variables';

.skill-wrapper {
  display: grid;
  grid-template-columns: $col-1 $col-4;

  .close-button {
    margin: 0;
    place-self: center;
  }
}
