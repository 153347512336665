@import '../../../../styles/variables';
@import '../../../../styles/mixin';

.itm-experience {
  display: flex;
  margin: 1.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px dashed transparentize($itm-dark, 0.8);
  }

  &_col {
    flex-basis: 50%;
    min-width: 50%;
  }
}

.place {
  @include itm-icon;
  margin: 1rem 0;

  &_title {
    @include font-bold;
    font-size: rem(18);
    margin-bottom: rem(24);
    padding-right: rem(20);
  }

  &_item {
    display: flex;
    align-items: center;
    margin-bottom: rem(7);

    p {
      margin: 0;
      padding-right: rem(20);
    }

    &.experience_length {
      color: $color-light-text;
    }

    &_multiline {
      flex-direction: column;
      margin-bottom: rem(0);
    }
  }
}

.empty-section {
  margin-bottom: 2rem;
}
