@import '../../../../styles/base';
@import '../../../../styles/variables';

.experience-container {
  @include pager-item;

  .experience-header{
    display: flex;
    padding-top: 2rem;
  }

  &_company {
    margin-bottom: 20px;
    input, textarea {
      height: 40px;
      @include fieldBorder;
    }
  }

  &_employment-status-duration-picker {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &_responsibilities,
  &_industry,
  &_business-project-description {
    margin-bottom: 20px;

    textarea {
      height: 120px;
      @include fieldBorder;
    }
  }

  &_company,
  &_responsibilities,
  &_industry,
  &_business-project-description,
  &_business-project {
    label, p {
      font-weight: bold;
    }

    &_currently_working {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      input{
        margin-right: 10px;
      }
    }
  }

  select:disabled {
    opacity: 0.6;
  }

  &_employment-status {
    input, select {
      width: 23px;
      height: 23px;
      margin-right: 10px;
      vertical-align: text-bottom;
      border: 1px dashed $border;
      cursor: pointer;
    }

    label {
      max-width: 250px;
    }

    select, input[type=checkbox], label {
      cursor: pointer;
      height: 23px;
    }

    @include pager-item-row;
  }

  &_employment-status-duration-start,
  &_employment-status-duration-end {
    input {
      width: 160px;
    }
  }

  &_current-employment {
    display: flex;
    align-items: center;
    margin: 0 10px;
    input{
      width: 23px;
    }
  }

  &_error {
    font-weight: normal !important;
  }

  .add-business-project-container {
    margin: 0 2rem 2rem;
    text-align: center;
  }
}

.experience {
  @include header;
  border: 1px transparent solid;
  border-radius: 15px;
  padding: 0 .5rem;

  .experience-details{
    padding: 0 1.5rem;
  }

  &.remove-experience{
    border: 1px red solid;
  }

  &_actions {
    padding: 40px 40px 20px 40px;
  }
}
