.paper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 52px 0;

  &_default {
    min-height: inherit;
    padding: 5px 0;
  }

  &_landing-page {
    main {
      width: 482px;
    }
  }

  &_content {
    max-width: 588px;
    width: 100%;
    background: #ffffff;
    // box-shadow: 0 34px 75px rgba(14, 132, 184, 0.06);
    border-radius: 15px;
  }

  @media screen and (min-width: 588px) {
    &_content {
      min-width: 588px;
    }
  }
}
