@import '../../../../styles//variables.scss';

.DraftEditor-root {
  display: block;
  border-radius: 6px;
  padding: 5px 10px;
  background: #fefefe;

  .public-DraftStyleDefault-unorderedListItem {
    display: block;
    position: relative;
    padding-left: 22px;
  }

  .public-DraftStyleDefault-block {
    display: inline-block;
  }

  li::before {
    content: '\2022';
    color: #ffcc00;
    font-size: 24px;
    font-weight: bold;
    display: inline-block;
    vertical-align: bottom;
    width: 22px;
    position: absolute;
    left: 0;
  }
}

.responsibilityBorder {
  border: 1px dashed $border;
  border-radius: 6px;
  background: #fefefe;
}

.responsibilityBorder-focus {
  border: 1px dashed transparent;
  box-shadow: 0 0 0 2px rgba(0, 0, 0);
  border-radius: 6px;
}

.hover-list {
  margin: 0 20px 20px;
}

.text-area-errors {
  display: flex;
  justify-content: space-between;
}

.text-area-errors span {
  margin: 5px 0;
}

.character-count {
  margin-left: auto !important;

  .count-exceeded {
    color: $itm-error;
  }
}
