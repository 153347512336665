@import 'src/styles/variables';

.error {
  &-container {
    color: $itm-error;
    margin-bottom: 10px;
    font-size: 14px;
    background: $bg-error;
    padding: 7px 16px;
    border-radius: 6px;
    border: $border-error;
    position: relative;

    .primary + & .error-position {
      grid-template-columns: $col-1 $col-2 $col-3 + $col-4;
    }

    .secondary + & .error-position {
      grid-template-columns: $col-1 $col-2 $col-3;
    }

    &.extended-nav-error {
      margin: 2rem 2rem 0;
    }
  }

  &-position {
    position: absolute;
    bottom: 100%;
    width: 100%;
    left: 0;
    display: grid;
    z-index: 2;

    div {
      display: flex;
      justify-content: center;
      opacity: 0;

      &::before {
        content: '';
        display: block;
        border: 7px solid transparent;
        border-bottom-color: $itm-error;
        opacity: 1;
      }
    }
  }
}

.error-list li::before {
  width: 4px;
  height: 4px;
  border-radius: 99px;
  background: $itm-error;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  margin-bottom: 1px;
}
