@import '../styles/variables.scss';

.blue {
  fill: $itm-blue;
  opacity: unset;
}

.default-size {
  width: 1rem;
  height: 1rem;
}
