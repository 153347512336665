@import '~bootstrap/scss/bootstrap';
@import '../../../../assets/fonts/bariol-regular/style.css';
@import '../../../../styles/variables';

@supports not (-webkit-touch-callout: none) {
  #root {
    height: 100%;
    width: 100%;
  }
}
@supports not (-webkit-touch-callout: none) {
  #root {
    height: 100%;
    width: 100%;
    filter: drop-shadow(0 34px 75px rgba(14, 132, 184, 0.06));
  }
}

html {
  height: 100%;
}

body {
  @include font-regular;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #f3f4f5;
  line-height: 120%;
}

* {
  word-break: break-word;
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Toastify {
  padding: 0;
  width: 276px;
  right: 0;

  &__toast {
    @include font-regular;
    min-height: 48px;
    border-radius: 10px 0 0 10px;
    margin-bottom: 0.3rem;
  }

  &__close-button {
    align-self: auto;

    > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
