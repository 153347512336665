@import '../../../styles/base';
@import '../../../styles/variables';

.summary-container {
  @include container;

  &_btn {
    white-space: nowrap;
  }
}

.summary {
  @include header;
}

.text-primary {
  color: $itm-blue;
}
