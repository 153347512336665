@import '../../../../styles/variables';
@import '../../../../styles/mixin';

.list {
  @include itm-icon;
  margin: 2rem 0 0;
  padding-bottom: 2rem;

  &:not(:last-child) {
    border-bottom: 1px dashed transparentize($itm-dark, 0.8);
    margin-bottom: rem(12);
  }

  &_item {
    display: flex;
    align-items: center;

    p {
      flex-grow: 1;
      margin: 0;
    }

    &--primary {
      color: $itm-blue;
      margin-bottom: 5px !important;
    }

    &--intended {
      margin-left: 1.6rem;
    }
  }
}

.empty-section {
  margin-bottom: 2rem;
}
