@import '../../../../styles/base';
@import '../../../../styles/variables';

.experience-container {
  &_business-project {
    padding: 0 2rem;
    background-color: #f8f9fb;
    border-radius: 15px;
    margin-bottom: 2rem;

    &.remove-experience {
      border: 1px red solid;
    }

    p {
      font-weight: bold;
    }

    input,
    select {
      height: 40px;
      @include fieldBorder;
    }

    .label {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .experience-container_employment-status-duration-start,
    .experience-container_employment-status-duration-end {
      margin: 0 5px 10px 5px;
    }

    .experience-container_employment-status-duration {
      margin: 0 -5px 10px -5px;
    }

    textarea::-webkit-resizer {
      display: none;
    }

    .hover-list {
      list-style-type: disc;
    }

    .duration-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-left: 10px;
      flex-wrap: wrap;

      input {
        width: 90px;
      }
    }

    .button--red {
      color: $itm-error;
    }

    .add-reponsibility {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      input {
        width: 90%;
      }

      .close-button {
        display: flex;
      }
    }
  }
}
