@import '../../../../styles/variables';
@import '../../../../styles/mixin';

$star-icon-font-size: 18px;

.starIcon {
    font-size: $star-icon-font-size;
    vertical-align: text-bottom !important;
    
    &Filled {
        @extend .starIcon;
        color: $itm-yellow;
    }

    &NotFilled {
        @extend .starIcon;
        color: $itm-light-blue;
    }
}

.tooltipSkillLevel {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    gap: rem(15);
    margin-bottom: rem(10);
}