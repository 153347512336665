@import '../../../../styles/variables';
@import '../../../../styles/mixin';

.certificates-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  > :first-child {
    grid-column: 1/-1;
  }
  .list {
    @include itm-icon;
    margin: 2rem 0 0;
    padding: 0 1.4rem 0 0;

    border-bottom: 0px;
    margin-bottom: rem(2);

    &_item {
      display: flex;
      align-items: center;

      p {
        flex-grow: 1;
        margin: 0;
      }

      &--primary {
        color: $itm-blue;
        font-weight: bold;
      }

      &--intended {
        margin-left: 1.6rem;
      }
    }
  }
}
