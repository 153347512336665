@import '../../../../styles/base';
@import '../../../../styles/variables.scss';

.landing-page {
  @include header;

  &_header {
    padding: 51px 0 12px;

    &--blue {
      color: $itm-blue;
    }
  }

  &_line {
    width: 38px;
    margin: 0;
    border: 1.5px solid #ffcc00;
    background: #ffcc00;
  }

  &_text {
    padding: 18px 0 59px;
    white-space: pre-line;
  }

  &_icon {
    align-self: center;
  }

  &_btn-container {
    padding: 40px 0 56px;
    align-self: center;
    max-width: 169px;
  }
}
