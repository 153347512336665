@import '../../styles/variables.scss';

.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #fff;

  svg {
    max-width: 100%;
    height: auto;
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--text {
      font-size: min(max(18px, 6vw), 32px);
      padding-top: 37px;
      text-align: center;
      color: $itm-dark;
    }
  }
}
