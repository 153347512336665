@import '../../../../styles/base';
@import '../../../../styles/variables.scss';

.language-container {
  @include container;
  margin-bottom: 30px;
}

.language {
  @include header;
  flex-direction: row;
  align-items: center;

  select {
    @include fieldBorder;

    max-width: 242px;
    width: 100%;
    height: 40px;
    background-color: transparent;
    margin: 0 10px 0 0;
  }

  p {
    max-width: 242px;
    width: 100%;
    display: flex;
    font-weight: bold;
  }
}

.hover-list {
  margin: 0 20px 20px;
}

.hover-container {
  padding-left: 10px;
}

.popover, .modal {

  .language-level {
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: $itm-blue;
  }

  .language-level-subtext {
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: $itm-dark;
  }

  .language-level-description {
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: $itm-dark;
    opacity: 0.7;
  }
}
