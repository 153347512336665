@import '../../../../styles/base';
@import '../../../../styles/variables.scss';
@import '../../../../styles/mixin.scss';

.confirmation-page {
  display: flex;
  margin: 2rem;

  &_header {
    @include header;
    justify-content: center;
    padding: 0 2rem 0 0;
  }

  &--blue {
    color: $itm-blue;
  }

  &_line {
    width: rem(38);
    margin: 0;
    margin-top: rem(8);
    border: 1.5px solid #ffcc00;
    background: #ffcc00;
  }

  &_text {
    padding: rem(18) 0 0;
    text-align: justify;

    &_end {
      margin-top: rem(10);
    }
  }

  &_icon {
    align-self: center;
    min-width: rem(210);
    max-height: rem(220);
  }

  &_btn-container {
    padding: rem(40) 0 rem(56);
    align-self: center;
    max-width: rem(169);
  }
}

@media (max-width: 569px) {
  .confirmation-page_header {
    padding: 0;
    margin-top: rem(20);
  }

  .confirmation-page {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}
