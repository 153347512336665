@import '../../../../styles/variables';
@import '../../../../styles/mixin';

.description-container {
  margin: 1rem 0;

  .description-container_title {
    font-size: rem(28);
    padding: rem(20) rem(34) rem(10) 0;
    margin: 0 rem(-34) rem(30) 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid transparentize($itm-dark, 0.9);
    }
  }

  .description-container_secondary-title {
    @include font-bold;
    font-size: rem(18);
    margin-bottom: rem(12);
  }
}

.description-list {
  @include itm-icon;

  &_tick-icon {
    align-self: flex-start;
    margin-top: 2px;
  }

  &_dot-icon {
    margin-top: rem(4);
    margin-bottom: auto;
  }

  &:not(:last-child) {
    margin-bottom: rem(12);
  }

  &_item {
    display: flex;
    align-items: center;
    margin-bottom: rem(12);

    p {
      flex-grow: 1;
      margin: 0;
      word-break: break-word;
    }
  }
}
