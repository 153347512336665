@import '../../../../styles/base';
@import '../../../../styles/variables.scss';

.aboutme-container {
  @include container;
  @include pager-item;

  display: flex;
  flex-direction: column;
  width: 100%;

  &_input {
    margin-bottom: 20px;

    label {
      margin-bottom: 8px;
      font-weight: bold;
    }

    textarea {
      @include fieldBorder;
      min-height: 120px;
    }

    textarea::-webkit-resizer {
      display: none;
    }
  }

  &_error {
    font-weight: normal !important;
  }

  .aboutme {
    @include header;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
  }
}

.aboutMeTextArea{
  overflow-y: hidden;
}
