@import '../../styles/variables.scss';

.button {
  border-radius: 10px;
  width: 100%;

  &--outline {
    color: $itm-blue;
    background: $itm-white;
    border: thin solid $itm-blue;
    &:hover{
      border-color: transparent;
      box-shadow: inset 0 0 0 2px $itm-blue;
    }
  }

  &--transparent {
    color: $itm-blue;
    background: transparent;
    border: 1px solid $itm-blue;
    box-sizing: border-box;
    &:hover{
      border-color: transparent;
      box-shadow: inset 0 0 0 2px $itm-blue;
    }
    &:active{
      background-color: $itm-light-blue;
    }
  }

  &--blue {
    box-shadow: 0 4px 46px rgba(0, 152, 218, 0.13);
    color: $itm-white;
    border-color: transparent;
    background: $itm-blue;
    &:hover{
      background-color: $button-blue-hover;
    }
    &:active{
      background-color: $itm-dark-blue;
    }
  }

  &--link {
    border: none;
    width: fit-content;
  }

  &--lg {
    padding: 11px 65.5px;
  }
  &--sm {
    padding: 11px 30px;
  }
    &--xs {
      padding: 11px 14px;
    }
}
