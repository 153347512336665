@import '../../../../styles/base';
@import '../../../../styles/variables.scss';

.education-container {
  @include container;
  @include pager-item;

  display: flex;
  flex-direction: column;
  width: 100%;

  .close-button {
    margin: 0;
  }

  .remove-button {
    margin-bottom: 40px;
    &:hover{
      box-shadow: none;
    }
  }

  &_title {
    padding-top: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    > svg {
      position: absolute;
      transform: translateX(15px);
      pointer-events: none;
    }
  }

  &_school {
    width: 0;
    flex-grow: 1;
    border: 1px dashed transparent;
    padding: 7px 10px 10px 42px !important;
    font-size: 22px;
    margin-right: 10px;
    border-radius: 10px;

    &:focus {
      box-shadow: 0px 10px 26px rgba(0, 152, 218, 0.12);
      outline: none;
      border: 1px dashed #dee9f2;
      color: $itm-blue;
    }
  }

  &_input {
    margin-bottom: 20px;

    label {
      margin-bottom: 8px;
      font-weight: bold;
    }

    input {
      height: 40px;
      @include fieldBorder;
    }
  }

  &_school-status {
    &-duration {
      p {
        display: flex;
        flex-basis: 100%;
      }

      label {
        font-weight: bold;
      }

      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-basis: 50%;
      width: auto;
      margin: 0 -10px 0px -10px;

      &-start,
      &-end {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        width: auto;
        margin: 0 10px 10px 10px;
        flex-basis: calc(50% - 20px);
        flex-grow: 1;

        > p {
          margin-bottom: 8px;
        }

        select.year_start,
        select.year_end,
        input.year_start,
        input.year_end {
          width: 244px;
          flex-grow: 1;
        }
      }
    }
  }
}

.education {
  @include header;
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  flex-direction: column;

  &_text {
    padding: 20px 0 30px;
    align-self: center;
  }

  &_icon {
    padding: 46px 0 20px;
  }

  &_actions {
    padding: 40px 40px 20px 40px;
  }

  select,
  input {
    height: 40px;
    @include fieldBorder;
    background-color: transparent;
  }

  input[type='checkbox'] {
    width: 23px;
    height: 23px;
    margin-right: 10px;
    vertical-align: text-bottom;
    border: 1px dashed $border;
    cursor: pointer;
  }
}
