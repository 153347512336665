@import '../../styles/variables.scss';

.headerText {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 10px;
  color: $itm-dark;
  padding-left: 14px;
}

.defaultDescription {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: $itm-dark;
}