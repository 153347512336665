@import '../../../../styles/variables.scss';

.experience-control {
  min-width: 95px;
  display: flex;
  justify-content: space-between;
  background: #f4f8fb;
  box-sizing: border-box;
  padding: 7px;
  border-radius: 0px 6px 6px 0px;

  &_years {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    pointer-events: none;
    background-color: transparent;

    &--color {
      opacity: 0.33;
    }
  }

  &_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #ffffff;
    border: 1px solid #dee9f2;
    border-radius: 3px;
  }
}
