@import 'variables';

// convert px to rem
@function rem($value, $size-mod: null) {
  @if not($size-mod) {
    $value: $value / $html-font-size + rem;
    @return $value;
  } @else {
    $value: $value / $size-mod + rem;
    @return $value;
  }
}

@mixin itm-icon-container {
  min-width: rem(16);
  height: rem(16);
  display: inline-block;
  background: center no-repeat;
  background-size: contain;
  margin-right: rem(10);
}

@mixin itm-icon {
  &_pin-icon {
    @include itm-icon-container;
    background-image: url('../assets/icon/icon-pin.svg');
    background-size: auto 90%;
  }

  &_calendar-icon {
    @include itm-icon-container;
    background-image: url('../assets/icon/icon-calendar.svg');
    background-size: 90% auto;
  }

  &_star-icon {
    @include itm-icon-container;
    background-image: url('../assets/icon/icon-star-active.svg');
  }

  &_star-gray-icon {
    @include itm-icon-container;
    background-image: url('../assets/icon/icon-star-inactive.svg');
  }

  &_pin-icon {
    @include itm-icon-container;
    background: url('../assets/icon/icon-pin.svg') center no-repeat;
  }

  &_hat-icon {
    @include itm-icon-container;
    background: url('../assets/icon/hat.svg') center no-repeat;
  }

  &_tick-icon {
    @include itm-icon-container;
    background: url('../assets/icon/tick.svg') center no-repeat;
  }

  &_dot-icon {
    @include itm-icon-container;
    background: url('../assets/icon/icon-dot.svg') center no-repeat;
  }

  &_certificate-icon {
    @include itm-icon-container;
    height: 1.2rem;
    background: url('../assets/icon/certificate.svg') center no-repeat;
  }
}
