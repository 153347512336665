@import '../../../../styles/base';
@import '../../../../styles/variables.scss';

.certificate-container {
  @include pager-item;

  &_title-input {
    width: 0;
    flex-grow: 1;
    border: 1px dashed transparent;
    padding: 7px 10px 10px 42px;
    font-size: 22px;
    margin-right: 10px;
    border-radius: 10px;

    &:focus {
      box-shadow: 0px 10px 26px rgba(0, 152, 218, 0.12);
      outline: none;
      border: 1px dashed #dee9f2;
      color: $itm-blue;
    }
  }

  &_issuer {
    input {
      height: 40px;
      width: 100%;
      @include fieldBorder;
    }

    .date-container {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
    }

    @include pager-item-row;

    &-duration-start,
    &-duration-end {
      align-content: flex-start;

      > p {
        margin-bottom: 8px;
      }
    }
  }

  &_error {
      font-weight: normal !important;
  }
}

.certificate {
  @include header;

  &_actions {
    padding: 40px 40px 20px 40px;
  }
}
