$itm-blue: #0098da;
$itm-dark: #4e5a62;
$itm-light-blue: #ebf3fa;
$itm-dark-blue: #00557a;
$itm-white: #ffffff;
$itm-yellow: #ffcc00;
$itm-error: #ff4444;
$itm-background: #f3f4f5;
$button-blue: #007bff;
$button-blue-hover: #1786bd;

$bg-error: transparentize($itm-error, 0.92);
$border-error: 1px dashed $itm-error;
$border: #dee9f2;
$borderRadius: 6px;
$border-action: #1c9cda;

$color-background: #f3f7f9;
$color-text: $itm-dark;
$color-light-text: gray;

$html-font-size: 16;

@mixin font-light {
  font-family: 'Bariol', sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin font-regular {
  font-family: 'Bariol', sans-serif;
  font-style: normal;
  font-weight: normal;
}

@mixin font-bold {
  font-family: 'Bariol', sans-serif;
  font-style: normal;
  font-weight: 600;
}

@mixin fieldBorder {
  border: 1px dashed $border;
  border-radius: 6px;
  padding: 5px 10px;
  box-sizing: border-box;
}

@mixin fieldBorderFocus {
  border-radius: 6px;
  box-shadow: 0 0 0 2px black;
  outline: none;
  -webkit-appearance: none;
  border: 1px dashed transparent;
}

// experience grid sizes
$col-1: 1fr;
$col-2: 100px;
$col-3: 100px;
$col-4: 40px;

// skills small screens media
$sm-media: 450px;
