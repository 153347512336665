.hit-box {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  min-height: 20px;
  cursor: pointer;
}
