@import '../../styles/variables';

.selectgroup-menu {
  overflow-y: scroll;
  height: 200px;
  width: 100%;
  padding-left: 0px;
  border: 1px solid #000000;
}

#dropdown-industry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-toggle::after {
  margin: 10px 0;
}

#industry {
  cursor: pointer;
}

input.search-industry:focus {
  border-color: none;
  box-shadow: none;
  border: 2px solid #000000;
}
