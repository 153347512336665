@import '../../../../../src/styles/variables';

.skill {
  &-item {
    display: grid;
    grid-template-columns: $col-1 $col-2 ($col-3 + $col-4);
    grid-template-rows: 40px;
    align-items: center;
    border: 1px solid $border;
    border-radius: $borderRadius;
    flex-grow: 1;

    &.secondary {
      grid-template-columns: $col-1 $col-2 $col-3;

      .input-container_input {
        padding-left: 40px;
      }
    }

    .input-container_input {
      border-radius: $borderRadius 0 0 $borderRadius;
      padding: 0 16px;
      text-overflow: ellipsis;

      &:not([readonly]):focus {
        box-shadow: 0 10px 26px rgba(0, 152, 218, 0.12);
        color: $itm-blue;
      }

      &:not([readonly]):focus + .input-container_icon path:first-child {
        fill: $itm-blue;
      }

      &:not([readonly]):focus + .input-container_icon path:last-child {
        fill: $itm-dark;
      }
    }
  }

  &-name {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

    .input-container_icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(100%, -50%);
      margin: 0;
    }
  }

  &-lvl {
    margin: 0;
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    padding: 0 10px;
  }

  &-experience {
    display: flex;
    padding: 0 7px;
    height: 100%;
    align-items: center;
    background-color: #f4f8fb;
    border-radius: 0 $borderRadius $borderRadius 0;
    border-left: 1px solid $border;

    .experience-control_btn {
      min-width: 26px;
      cursor: pointer;

      button {
        margin-top: 0;
        display: flex;
        width: 100%;
        height: 100%;
      }

    }

    :hover {
      button {
        border: 2px solid $itm-blue;
      }
    }
    :active {
      button {
        border: 2px solid $itm-blue;
        background-color: $itm-light-blue;
      }
    }
  }
}

.skill-item {
  &.error {
    &-skillName {
      .skill-name {
        path {
          fill: $itm-error;
        }

        input {
          background: $bg-error;
          color: $itm-error;
          border: $border-error;
          margin-left: -1px;
        }
      }

      & + .error-container > .error-position {
        > div:nth-child(1) {
          @media (min-width: $sm-media + 1) {
            opacity: 1;
          }
        }
      }
    }

    &-level {
      .skill-lvl {
        background: $bg-error;
        color: $itm-error;
        border: $border-error;
        margin-left: -1px;
      }

      & + .error-container > .error-position {
        > div:nth-child(2) {
          @media (min-width: $sm-media + 1) {
            opacity: 1;
          }
        }
      }
    }

    &-years {
      .skill-experience {
        background: $bg-error;
        color: $itm-error;
        border: $border-error;
        padding-left: 8px;
        z-index: 1;
      }

      & + .error-container > .error-position {
        > div:nth-child(3) {
          @media (min-width: $sm-media + 1) {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: $sm-media) {
    &[class*='error'] + .error-container .error-position {
      > div:nth-child(1) {
        opacity: 1;
      }
    }
  }
}

.skill-wrapper {
  row-gap: 6px;

  .error-container {
    grid-row-start: 2;
  }
}
