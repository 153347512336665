@import '../../../../../src/styles/variables';
@import '../../../../../src/styles/base';
$star-icon-font-size: 18px;

.hoverHintButton {
  background-color: $itm-dark;
  color: $itm-white;
  border-radius: 50%;
  border: none;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  padding: 0 !important;
}

.withTitle {
  margin-left: 5px;
  vertical-align: bottom;
}

@media (max-width: $sm-media) {
  .skills_year_exp {
    display: none;
  }
}

.headerText {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: $itm-dark;
}

.starsDescriptionText {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: $itm-dark;
}

.starsIconsContainer {
  text-align: right;
}

.descriptionText {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: $itm-dark;
  opacity: 0.7;
}

.starIcon {
  font-size: $star-icon-font-size;
  vertical-align: text-bottom !important;

  &Filled {
    @extend .starIcon;
    color: $itm-yellow;
  }

  &NotFilled {
    @extend .starIcon;
    color: $itm-light-blue;
  }
}
