@import 'src/styles/base';
@import 'src/styles/mixin';

.navigation-container {
  @include container;
  border-radius: rem(10);
  box-shadow: 0 -5px 0 #e3edf5 inset;

  &_btn {
    padding-top: rem(25);
    padding-bottom: rem(30);

    button {
      width: auto;
    }
  }
}
