@import '../../../../styles/base';

.modal_container {
  @include container;

  &.modal-content {
    border: 0;
    border-radius: 15px;
  }

  &_btn {
    white-space: nowrap;

    .button {
      margin-bottom: 0;
    }

    @media screen and (max-width: 587px) {
      width: 100%;
      padding: 10px;
      .button:nth-child(2) {
        margin-bottom: 10px;
      }
    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-footer {
    padding: 0;
    border-top: 0;
  }
}
