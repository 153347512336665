@import '../../../../../src/styles/variables';

//remove bootstrap
.card {
  border: none !important;
  background: none !important;
  border-radius: rem(10);

  &-header {
    border: none !important;
    background: none !important;
    padding: 1.25rem;
  }
}

// component
.accordion-container {
  margin: 0 !important;

  &_header {
    &--toggle {
      width: 100% !important;
      padding: 0 !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      margin-top: 0 !important;
      text-align: left !important;

      &:hover,
      &:focus {
        box-shadow: none !important;
        text-decoration: none !important;
      }
    }

    &--title {
      margin: 0;
      font-size: 24px;
      line-height: 26px;
      color: $itm-dark;
    }
  }

  &.error {
    .paper_content {
      border: $border-error;

      .accordion-container_header {
        background: $bg-error !important;
        border-radius: rem(10);
      }
    }
  }
}

.arrow-container {
  border: 1px solid $itm-blue;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  &:hover{
    border-color: $itm-blue;
    border-width: 2px;
  }
  &:active{
    background-color: $itm-light-blue;
  }
}
.arrow-container:focus {
  outline: 2px solid #6fabeb;
}